import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js';
import componentsRouter from './ComponentRoutes'




Vue.use(VueRouter)

const routes = [

  {
    path: "/login",
    component: () => import("@/components/login/Login.vue"),
    name: 'Login',
    meta: { requiresAuth: false, public: true }
  },

  componentsRouter,

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {

  store.dispatch('setRouter', to.path)

  let title = to.name !== undefined ? `${to.name} |` : ""
  document.title = ` ${title} ${process.env.VUE_APP_ACRONYM}`



  const loggedIn = Object.keys(store.getters.getCurrentUser).length > 0 ? true : false;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isPublic = to.matched.some(record => record.meta.public)



  if (requiresAuth) {

    if (loggedIn) {
      next();
    } else {
      next("/login");
    }
  } else if (isPublic && loggedIn) {
    //A Logged-in user can't go to login page again

    next("/");
  } else {
    next();
  }
});

export default router


