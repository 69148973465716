<template>
  <v-navigation-drawer
    v-model="drawerData"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    color="blue-grey lighten-5"
    class="text--white"
  >
    <template v-slot:prepend>
      <v-list-item two-line class="user-banner">
        <v-list-item-avatar>
          <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
        </v-list-item-avatar>
        <v-list-item-content :color="'primary'">
          <span style="color:primary">
            {{ user.first_name + " " + user.surname }}
          </span>
          <v-list-item-subtitle>
            <!--{{ userTitle | UpperCase }}-->
            <div>
              <ul>
                <div v-for="(role, index) in user.roles" :key="index">
                  <li>{{ role.name }}</li>
                </div>
              </ul>
            </div>
            <span v-if="user.location">{{ user.location.name }}</span>

            <br />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>
    <perfect-scrollbar>
      <v-list>
        <template v-for="(menu, index) in menus">
          <v-list-group
            :key="index"
            :prepend-icon="menu.icon"
            v-if="menu.children && menu.children.length !== 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="menu.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, index) in menu.children"
              :key="index"
              link
              router-link
              :to="child.url"
              class="pl-8"
            >
              <v-list-item-icon>
                <v-icon small medium v-text="child.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="menu.title" link router-link :to="menu.url">
            <v-list-item-action>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-divider></v-divider>
      <template v-for="(menu, index) in demarcatedMenus">
        <v-list-group
          :key="index"
          :prepend-icon="menu.icon"
          v-if="menu.children && menu.children.length !== 0"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="menu.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(child, index) in menu.children"
            :key="index"
            link
            router-link
            :to="child.url"
            class="pl-8"
          >
            <v-list-item-icon>
              <v-icon small medium v-text="child.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="menu.title" link router-link :to="menu.url">
          <v-list-item-action>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    //drawer: { type: Boolean },
    drawer: {
      type: Boolean,
    },
  },

  data: () => ({
    menus: [],
    demarcatedMenus: [],
  }),
  methods: {
    getMenus() {
      // console.log('getMenus', JSON.parse(localStorage.getItem('eExam-user')))

      //let user = JSON.parse(this.$mixins.getItem("eExam-user"));
      let user = this.user;
      let menu = user && user.menus ? user.menus : [];
      // console.log('menu', menu)

      if (menu && menu.length > 0) {
        //console.log('mmm', this.menus)
        return (this.menus = menu
          .map((menu) => ({
            order: menu.order,
            "icon-alt": menu.icon,
            icon: menu.icon,
            url: menu.url,
            title: menu.name,
            children:
              menu.submenus && menu.submenus.length != 0
                ? menu.submenus
                    .map((child) => ({
                      order: child.order,
                      icon: child.icon,
                      url: child.url,
                      title: child.name,
                      demarcated: child.demarcated,
                    }))
                    .filter(
                      (item) =>
                        item.demarcated === null ||
                        item.demarcated === undefined ||
                        item.demarcated === false
                    )
                    .sort(function(a, b) {
                      if (a.order > b.order) return 1;
                      return -1;
                    })
                : [],
          }))
          .filter(
            (item) =>
              (item.children && item.children.length != 0) ||
              (item.url && item.url.length !== 0)
          )
          .sort(function(a, b) {
            if (a.order > b.order) return 1;
            return -1;
          }));
      } else {
        return [];
      }
    },

    getDemarcatedMenus() {
      // console.log('getMenus', JSON.parse(localStorage.getItem('eExam-user')))

      let user = this.user;
      // let user = JSON.parse(this.$mixins.getItem("eExam-user"));
      let menu = user && user.menus ? user.menus : [];
      // console.log('menu', menu)

      if (menu && menu.length > 0) {
        //console.log('mmm', this.menus)
        return (this.demarcatedMenus = menu
          .map((menu) => ({
            order: menu.order,
            "icon-alt": menu.icon,
            icon: menu.icon,
            url: menu.url,
            title: menu.name,
            children:
              menu.submenus && menu.submenus.length != 0
                ? menu.submenus
                    .map((child) => ({
                      order: child.order,
                      icon: child.icon,
                      url: child.url,
                      title: child.name,
                      demarcated: child.demarcated,
                    }))
                    .filter((item) => item.demarcated === true)
                    .sort(function(a, b) {
                      if (a.order > b.order) return 1;
                      return -1;
                    })
                : [],
          }))
          .filter((item) => item.children && item.children.length > 0)
          .sort(function(a, b) {
            if (a.order > b.order) return 1;
            return -1;
          }));
      } else {
        return [];
      }
    },
  },
  computed: {
    drawerData: {
      get() {
        return this.drawer;
      },
      set(value) {
        value;
      },
    },
    // user() {
    //   return this.$store.getters.getCurrentUser;
    // },

    user() {
      return this.$mixins.getItem("eExam-user");
    },
  },
  mounted() {
    this.getMenus();
    this.getDemarcatedMenus();
  },
};
</script>

<style>
.ps {
  /* min-height: 400px; */
  height: 600px;
}
</style>
