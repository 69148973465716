import axios from "axios";
import SecureLs from 'secure-ls';
const ls = new SecureLs({ encodingType: 'des', isCompression: false, encryptionSecret: 'eAMIS!@#' })
let user = ls.get("eExam-user");

const state = {
  isLoggedIn: false,
  //currentUser: JSON.parse(localStorage.getItem("eExam-user")),
  currentUser: user,
  loginError: {},
  currentRouter: "",
  appTheme: null,

};

const getters = {
  getCurrentUser: state => {

    return state.currentUser;
  },

  isLoggedIn: state => {
    return state.isLoggedIn;
  },

  loginError: state => {
    return state.loginError;
  },

  currentRouter: state => {

    return state.currentRouter;
  },



  getThemeColor: state => {
    return state.appTheme;
  }
};


const actions = {
  async getMetadataCategories({ getters, dispatch }) {
    let user = getters.getCurrentUser;

    //console.log("dispatch:", dispatch);


    const headers = { Authorization: `Bearer ${user.token}` };

    let options = { headers, params: { size: 500 } };

    await dispatch("DataCategory/getAll", options);

  },



  async setRouter({ commit }, payload) {
    commit("ROUTER", payload);
  },



  async logout({ commit }) {


    await axios.post('/api/v1/logout').then(response => {

      commit("LOGOUT", { response: response });
    });

  },




};

const mutations = {
  AUTHENTICATE(state, payload) {
    state.currentUser = payload;
    state.isLoggedIn = true;
  },

  LOGOUT(state) {
    state.currentUser = {};
    state.isLoggedIn = false;
    ls.remove("eExam-user");
    ls.remove("eExam-categories");

  },

  setItem(key, val) {
    return ls.set(key, val);
  },

  getItem(state, key) {

    state.appTheme = ls.get(key);

  },


  removeItem(key) {
    return ls.remove(key);
  },

  loginError(state, error) {
    // console.log('mmmm', { ...error })
    state.loginError = { ...error };
  },

  ROUTER(state, payload) {
    //console.log('kk', payload);

    state.currentRouter = payload;

  },

};

export default {
  state,
  getters,
  actions,
  mutations,


};
