import store from "../store";
import axios from "axios";


import _ from "lodash";
import { getByCodeAndName as searchOptions } from "../components/metadata/services/data-category-service"


import SecureLs from 'secure-ls';

const ls = new SecureLs({ encodingType: 'des', isCompression: false, encryptionSecret: 'eAMIS!@#' })



export const functions = {

  searchMetaOptions(params) {

    return searchOptions({
      ...params,
      size: 5000,

    }).then((response) => {
      return response.data
        ? response.data
          .map((option) => ({
            ...option,
          }))
          .sort(function (a, b) {

            return a.name > b.name ? 1 : -1
          })
          .map((item, index) => ({
            ...item,
            index: ++index,
          }))
        : [];
    })

  },

  computed: {
    academicYear() {
      return this.user
    }
  },


  fetchByCategory(category) {

    return store
      .dispatch("DataCategory/getCategoryOptions", category)
      .then((response) => {
        return response.sort((a, b) => a.name > b.name ? 1 : -1);
      });
  },

  getAge(date) {
    var age = new Date().getFullYear() - new Date(date).getFullYear();
    var m = new Date().getMonth() - new Date(date).getMonth();
    if (m < 0 || (m === 0 && new Date().getDate() < new Date(date).getDate())) {
      age = age - 1;
    }
    return age;
  },

  can(action, resource) {
    resource = resource.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
    let user = store.getters.getCurrentUser;
    let permissions = user.authorities;
    if (user) {
      return _.find(permissions, {
        action: action.toUpperCase(),
        resource: resource
      })
        ? true
        : false;
    } else {
      console.log("no current user");
    }
  },


  concatenate(str = Array) {
    return "".concat(str.join(""));
  },



  titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  },



  acronym(str) {
    return str.match(/\b([A-Z])/g).join("");
  },

  maxDate: new Date().toISOString().slice(0, 10),



  async print(divId) {
    console.log("divId", divId)
    return {
      printLoading: true,
      printObj: {
        id: divId,
        popTitle: 'good print',
        extraCss: "https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          vue.printLoading = true
          console.log('loading...')
        },
        openCallback(vue) {
          vue.printLoading = false
          console.log('printing ...')
        },
        closeCallback(vue) {
          console.log('closed...', vue)
        }
      }
    };
  },


  // user() {
  //   return Object.keys(store.getters.getCurrentUser).length > 0 ? store.getters.getCurrentUser : null
  // },  

  user() {
    return this.getItem("eExam-user");
  },


  academicYear() {
    return store.getters.getCurrentUser.academicYear
  },


  pageTitles(titleName) {

    //let user = JSON.parse(localStorage.getItem("eExam-user"));
    let user = this.user();
    //let user = this.user();
    // console.log(user);
    if (user) {
      let menu = user && user.menus ? user.menus : [];
      let url = store.getters.currentRouter;
      let parentUrl = (url === "/") ? true : false;


      let title = {}

      if (parentUrl) {
        //for parent menu logic
        title = menu.find(r => r.url === "/")
      } else {
        /** menuItems Logic */

        title = menu.flatMap(el => el.submenus).find(p => p.url.includes("/") ? p.url === url : p.url === url.replace("/", "")
        )
      }


      return { icon: title.icon, name: titleName }


    } else {
      return { icon: null, name: null }

    }

  },

  currentUser() {
    return store.getters.getCurrentUser;
  },



  setItem(key, value) {
    return ls.set(key, value);
  },


  getItem(key) {
    return ls.get(key);
  },



  removeItem(key) {
    return ls.remove(key);
  },




  async checkExists(api, key) {
    return new Promise(function (resolve, reject) {

      let data = { key, exists: false }

      try {

        axios.get(api, { params: key }).then(response => {
          if (Object.entries(response.data).length > 0) {
            data.exists = true;
            data.response = response.data;
          } else {
            data.exists = false;
          }


          return resolve(data);
        })

      } catch (e) {
        return reject(e);
      }


    })
  },


  maxDate(maxDate) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - maxDate);
    return date.toISOString().substr(0, 10);
  },

  minDate(minDate) {
    const date = new Date();
    date.setFullYear(date.getFullYear() - minDate);
    return date.toISOString().substr(0, 10);
  },
};
