import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import "@/assets/main.css";
import "@/components/shared";
import { functions } from "@/mixins/functions";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import * as  inputDirectives from './directives/InputDirectives'
import '@/requests'
import * as filters from '@/filters' // global filters
import elDragDialog from '@/directives/el-drag-dialog' // base on element-ui

Vue.use(PerfectScrollbar);
Vue.use(ElementUI);

Vue.prototype.$mixins = functions;


Vue.config.productionTip = false

Vue.directive('drag', elDragDialog)


// register  input global utility directive
Object.keys(inputDirectives).forEach(key => {

  Vue.directive(key, inputDirectives[key])
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
new Vue({
  rtl: true,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

