
import store from '@/store'
import router from '@/router'

const snackbar = {}

const showErrorSnackbar = response => {
    snackbar.message = response.data.errors;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;


};

const show500ErrorSnackbar = response => {
    // console.log("500", response.statusText);
    snackbar.message = `500: ${response.statusText}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};

const show401ErrorSnackbar = response => {
    //console.log("401", response);
    snackbar.message = `401: ${response.statusText}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};
const show409ErrorSnackbar = response => {
    // console.log("409", response);
    // snackbar.message = `409: ${response.statusText}`;
    snackbar.message = `409: ${response.data.message}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};
const show400ErrorSnackbar = response => {
    // console.log("400", response);
    snackbar.message = response.data.title
        ? `400: ${response.data.title}`
        : `400: ${response.data.message}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;

    store.commit('LOGOUT');
    router.push("/login");//session expired go to login page
};
const show422ErrorSnackbar = response => {
    // console.log("400", response);

    let listErrors = ''
    let validationErrors = Object.values(response.data.errors).flat();
    validationErrors.forEach(function (value) {
        listErrors += "<li>" + value + "</li>"

    });

    snackbar.message = `422: ${listErrors}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};
const show404ErrorSnackbar = response => {
    // console.log("400", response);
    snackbar.message = response.data.title
        ? `404: ${response.data.title}`
        : `404: ${response.data.message}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};

const show405ErrorSnackbar = response => {
    // console.log("405", Session xpired);
    snackbar.message = response.data.title
        ? `405: ${response.data.title}`
        : `405: ${response.data.message}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";
    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";


};

const show403ErrorSnackbar = response => {
    // console.log("400", response);
    snackbar.message = response.data.title
        ? `403: ${response.data.title}`
        : `403: ${response.data.message}`;
    snackbar.show = true;
    snackbar.color = "red";
    snackbar.icon = " mdi-alert";

    store.state.Helper.snackbar = snackbar;
    snackbar.title = "Action Failed...."
    snackbar.status = "error";

};

const errorHandler = (error) => {
    //console.log('errors kk ',error.response.data.errors);

    //const errors=Object.values(error?.response?.data?.errors).flat();
    switch (error.response.status) {
        case 500:
            show500ErrorSnackbar(error.response);
            break;

        case 400:
            show400ErrorSnackbar(error.response);
            break;
        case 401:
            show401ErrorSnackbar(error.response);
            break;
        case 403:
            show403ErrorSnackbar(error.response);
            break;
        case 404:
            show404ErrorSnackbar(error.response);
            break;
        case 405:
            show405ErrorSnackbar(error.response);
            break;
        case 409:
            show409ErrorSnackbar(error.response);
            break;
        case 422:
            show422ErrorSnackbar(error.response);
            break;
        default:
            showErrorSnackbar(error.response);
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {


    switch (response.config.method) {
        case "put":
        case "post":
        case "delete":
            snackbar.show = true;
            snackbar.title = "Successful..."
            snackbar.status = "success";
            snackbar.color = "primary";
            snackbar.icon = "mdi-checkbox-marked-circle";
            snackbar.message = response.data.message;
            store.state.Helper.snackbar = snackbar;





    }

    //store.state.SweetAlert.sweetAlerts = {};

    return response;
};

export { successHandler, errorHandler }