<template>
  <div class="dashboard">
    <h1>Hello Welcome</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  props: {},
  methods: {},

  computed: {
    user() {
      return this.$mixins.user();
    },
  },
  created() {},
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
