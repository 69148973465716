
import Home from '@/components/Landing/Home.vue'
import { usersRoutes } from "@/components/users";
import { dataOptionsRoutes } from '@/components/metadata'
import { roleRoutes } from '@/components/roles'
import { mainMenuRoutes } from '@/components/menus/main'
import { subMenuRoutes } from '@/components/menus/sub-menus'
import { levelRoutes } from '@/components/levels'
import { permissionRoutes } from '@/components/permissions'
import { locationRoutes } from '@/components/locations'
import { subjectRoutes } from '@/components/subjects'
import { smsRoutes } from '@/components/sms';
import { schoolClassesRoutes } from '@/components/school-classes';
import { academicYearsRoutes } from '@/components/academic-years';
import { studentsRoutes } from '@/components/students/studentsList';
import { studentSubjectsRoutes } from '@/components/students/Student-Subject';
import { schoolSubjectsRoutes } from '@/components/school-subjects'
import { schoolProfileRoutes } from '@/components/school-profile'
import { examGradesRoutes } from '@/components/exams-grades';
import { divisionGradesRoutes } from '@/components/division-grades';
import { examinationsRoutes } from '@/components/examinations';
import { cacheRoutes } from '@/components/cache-configurations'
import { examScoresRoutes } from '@/components/exams-scores'
import { examsAttendanceRoutes } from '@/components/exams-attendance'

const componentsRouter = {
    path: '/',
    component: Home,

    children: [
        ...usersRoutes,
        ...dataOptionsRoutes,
        ...roleRoutes,
        ...mainMenuRoutes,
        ...subMenuRoutes,
        ...levelRoutes,
        ...permissionRoutes,
        ...locationRoutes,
        ...subjectRoutes,
        ...smsRoutes,
        ...schoolClassesRoutes,
        ...academicYearsRoutes,
        ...studentsRoutes,
        ...studentSubjectsRoutes,
        ...schoolSubjectsRoutes,
        ...schoolProfileRoutes,
        ...examGradesRoutes,
        ...divisionGradesRoutes,
        ...examinationsRoutes,
        ...cacheRoutes,
        ...examScoresRoutes,
        ...examsAttendanceRoutes,

        {
            path: "/",
            name: 'Dashboard',

            component: () => import("../components/dashboard/Dashboard"),
            meta: { requiresAuth: true }
        },
        {
            path: "/role-menu-permissions",
            name: 'Role Menu Permissions',
            component: () => import("../components/permissions/RoleMenuPermission"),
            meta: { requiresAuth: true }
        },




    ]
}

export default componentsRouter
